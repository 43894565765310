import React from 'react';
import SectionMain from './components/mirror/SectionMain'

function App() {
  return (
    <div>
        <SectionMain/>
    </div>
  );
}

export default App;
