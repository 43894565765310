import React, { Component } from "react";
import Web3 from "web3";
import {
  Multicall,
  ContractCallResults,
  ContractCallContext,
} from "ethereum-multicall";
import { Rnd } from 'react-rnd'
import './Dashboard.css'
import { MIRROR_ADDRESS, MIRRORABI } from "../../config";
import Grid from "@material-ui/core/Grid";
import folderIcon from './folder.png';
import villain0 from './villain0.png';
import villain1 from './villain1.png';
import villain2 from './villain2.png';
import villain3 from './villain3.png';
import villain4 from './villain4.png';
import villain5 from './villain5.png';
import villain6 from './villain6.png';
import villain7 from './villain7.png';
import villain8 from './villain8.png';
import villain9 from './villain9.png';
import idCard from './idCard.png';
import notepadText from './notepadText.png';
import meme1 from './meme1.gif';
import meme2 from './meme2.gif';
// ... rest of your imports

const BSC_RPC_URL = "https://bsc-dataseed1.binance.org/";

const villainImages = [villain0, villain1, villain2,villain3, villain4, villain5, villain6, villain7, villain8, villain9];

export default class SectionMain extends Component {
  constructor() {
    super();
    this.state = {
      account: "",
      web3: null,
      loading: true,
      showLoading: false,
      showWindows: Array(11).fill(true), // To manage window visibility
      mirrorContract: "",
      mirrorBalance: "",
      morWholeBurn: "",
      morRFIBurn: "",
      userFarmedMor: "",
      morReflected: "",
      morCireculating: "",
      mirrorPrice: "",
      farmer0: "",
      farmer1: "",
      farmer2: "",
      farmer3: "",
      farmer4: "",
      farmer5: "",
      farmer6: "",
      farmer7: "",
      farmer8: "",
      farmer9: "",
      farmer10: "",
      farmer11: "",
      farmer12: "",
      farmer13: "",
      farmer14: "",
      farmer15: "",
      farmer16: "",
      farmer17: "",
      farmer18: "",
      farmer19: "",
      farmer0amt: "",
      farmer1amt: "",
      farmer2amt: "",
      farmer3amt: "",
      farmer4amt: "",
      totalFarmers: 0,
      farmerCheckedCount: 0,
      farmingLeaderboardArr: [
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
      ],
      mirrorCardWidth: "95%",
      terminalMessage: true, // Initial state
      villainIndexes: Array(5).fill(null).map(() => Math.floor(Math.random() * 10)), // Array of 5 random indexes
      isSecretUnlocked: false,
      showSecretContent: false,
      enlargeNotepad: false,
      notBSC: null,
      topFarmers:[],
      showLeaderboard:false,
      showMobileHomework: false,
      showMobileLeaderboard: false,
      showMobileTerminal: false,
    };
  }

  isMobile = () => {
    return window.innerWidth <= 600; // You can adjust the width threshold as needed
  }

  componentDidUpdate(prevProps, prevState) {
    if ([this.state.farmer0, this.state.farmer1, this.state.farmer2, this.state.farmer3, this.state.farmer4].some((farmer, index) => farmer !== prevState[`farmer${index}`])) {
      // Update the villain indexes when farmer addresses change
      this.setState({
        villainIndexes: Array(5).fill(null).map(() => Math.floor(Math.random() * 10))
      });
    }
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  }
  
  

  componentDidMount() {
    this.connectToBSC();
    const shuffledVillains = this.shuffleArray([...villainImages]);
    this.setState({ shuffledVillains: villainImages.slice(0, 10) });
  }
  

    //Connect to web3
    async connectToBSC(){
      var newWeb3 = null;
      if (window.ethereum){
        newWeb3 = new Web3(window.ethereum);
        window.ethereum.enable();
        this.setState({ web3: newWeb3 });
        
        window.ethereum.on("accountsChanged", (accounts) => {
          this.setState({ account: accounts });
          this.setState({ approved: false });
          
        })
      }
      // Legacy dapp browsers...
      else if (window.web3) {
        newWeb3 = new Web3(window.web3.currentProvider);
        this.setState({ web3: newWeb3 });
        window.web3.currentProvider.on("accountsChanged", (accounts) => {
          this.setState({ approved: false });
          
        })
      }
      // Non-dapp browsers...
      else {
        console.log("Not a DAPP browser");
      }
      
      
        this.connectAndLoad();
      }

      async checkChainId(){
        var chainID = await this.state.web3.eth.getChainId();
        if (chainID != '250'){
          this.setState({notBSC: true});
        }
        else{
          this.setState({notBSC: false});
        }
      }

    // Connect and load contracts and data as needed 
    async connectAndLoad(){
      if (this.state.web3 != null){
        const accounts = await this.state.web3.eth.getAccounts();
        try{
          if (accounts[0].substr(0, 2) == '0x'){
            this.setState({ account: accounts });
          }
          else{
            alert("Connection failed");
          }
        }
        catch (err){
          console.log("No wallet address");
        }
        this.checkChainId();
        const morContract = new this.state.web3.eth.Contract(MIRRORABI, MIRROR_ADDRESS);
        this.setState({ mirrorContract: morContract });
        this.setState({ loading: false })
        this.loadDataUsingMulticall();
        setInterval(async () => {
          this.loadDataUsingMulticall();
        }, 5000);
  
      }
    }

  async loadDataUsingMulticall() {
    const { web3 } = this.state;
    var multicall = null;
    // Checksum the addresses
    let userAddress = this.state.account[0] ? Web3.utils.toChecksumAddress(this.state.account[0]) : null;
    const burnAddress = Web3.utils.toChecksumAddress("0x0000000000000000000000000000000000000000");
    const deadAddress = Web3.utils.toChecksumAddress("0x000000000000000000000000000000000000dEaD");
    const zero = "0";
    const one = "1";
    const two = "2";
    const three = "3";
    const four = "4";
    
    //FEES//
    //_devFee
    //farmerFees
    //so totalFees = _devFee+farmerFee


    multicall = new Multicall({
      web3Instance: web3,
      tryAggregate: true,
    });

    
    const contractCallContext = [
       {
        reference: "mirrorBalance",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [
          {
            methodName: "balanceOf",
            methodParameters: [userAddress],
          },
        ],
      },
      {
        reference: "morWholeBurn",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [
          {
            methodName: "balanceOf",
            methodParameters: [burnAddress],
          },
        ],
      }, 
      {
        reference: "_devFee",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{methodName:"_devFee", methodParameters: [] }],
      },
      {
        reference: "farmerFees",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{methodName:"farmerFees", methodParameters: [] }],
      },
      {
        reference: "numFarmers",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{methodName: 'numFarmers',methodParameters: [] }],
      },
      {reference: "farmer0",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:[zero] }],
      },
      {reference: "farmer1",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:[one] }],
      },
      {reference: "farmer2",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:[two] }],
      },
      {reference: "farmer3",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:[three] }],
      },
      {reference: "farmer4",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:[four] }],
      },
      {reference: "farmer5",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:["5"] }],
      },
      {reference: "farmer6",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:["6"] }],
      },
      {reference: "farmer7",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:["7"] }],
      },
      {reference: "farmer8",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:["8"] }],
      },
      {reference: "farmer9",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:["9"] }],
      },
      {reference: "farmer10",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:["10"] }],
      },
      {reference: "farmer11",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:["11"] }],
      },
      {reference: "farmer12",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:["12"] }],
      },
      {reference: "farmer13",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:["13"] }],
      },
      {reference: "farmer14",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:["14"] }],
      },
      {reference: "farmer15",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:["15"] }],
      },
      {reference: "farmer16",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:["16"] }],
      },
      {reference: "farmer17",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:["17"] }],
      },
      {reference: "farmer18",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:["18"] }],
      },
      {reference: "farmer19",
        contractAddress: MIRROR_ADDRESS,
        abi: MIRRORABI,
        calls: [{ methodName: "farmers", methodParameters:["19"] }],
      },
    ];

    

    const callResults = await multicall.call(contractCallContext);
    
    
    // Accessing individual results
    const mirrorBalance = callResults.results.mirrorBalance.callsReturnContext[0].returnValues[0].hex*1/(1000000*(10**18));
    const morWholeBurn=callResults.results.morWholeBurn.callsReturnContext[0].returnValues[0].hex*1/(1000000*(10**18)); 
    const devFees = callResults.results._devFee.callsReturnContext[0].returnValues[0].hex*1;
    const farmerFees = callResults.results.farmerFees.callsReturnContext[0].returnValues[0].hex*1;
    const totalFees = devFees+farmerFees;
    const numFarmers = callResults.results.numFarmers.callsReturnContext[0].returnValues[0].hex*1;
    const farmer0 = callResults.results.farmer0.callsReturnContext[0].returnValues[0];
    const farmer1 = callResults.results.farmer1.callsReturnContext[0].returnValues[0];
    const farmer2 = callResults.results.farmer2.callsReturnContext[0].returnValues[0];
    const farmer3 = callResults.results.farmer3.callsReturnContext[0].returnValues[0];
    const farmer4 = callResults.results.farmer4.callsReturnContext[0].returnValues[0];
    const farmer5 = callResults.results.farmer5.callsReturnContext[0].returnValues[0];
    const farmer6 = callResults.results.farmer6.callsReturnContext[0].returnValues[0];
    const farmer7 = callResults.results.farmer7.callsReturnContext[0].returnValues[0];
    const farmer8 = callResults.results.farmer8.callsReturnContext[0].returnValues[0];
    const farmer9 = callResults.results.farmer9.callsReturnContext[0].returnValues[0];
    const farmer10 = callResults.results.farmer10.callsReturnContext[0].returnValues[0];
    const farmer11 = callResults.results.farmer11.callsReturnContext[0].returnValues[0];
    const farmer12 = callResults.results.farmer12.callsReturnContext[0].returnValues[0];
    const farmer13 = callResults.results.farmer13.callsReturnContext[0].returnValues[0];
    const farmer14 = callResults.results.farmer14.callsReturnContext[0].returnValues[0];
    const farmer15 = callResults.results.farmer15.callsReturnContext[0].returnValues[0];
    const farmer16 = callResults.results.farmer16.callsReturnContext[0].returnValues[0];
    const farmer17 = callResults.results.farmer17.callsReturnContext[0].returnValues[0];
    const farmer18 = callResults.results.farmer18.callsReturnContext[0].returnValues[0];
    const farmer19 = callResults.results.farmer19.callsReturnContext[0].returnValues[0];
   
    
    

    // Update state with all the gathered data
    this.setState({
      mirrorBalance,
      morWholeBurn,
      
      totalFees,
      numFarmers,
      farmer0,
      farmer1,
      farmer2,
      farmer3,
      farmer4,
      farmer5,
      farmer6,
      farmer7,
      farmer8,
      farmer9,
      farmer10,
      farmer11,
      farmer12,
      farmer13,
      farmer14,
      farmer15,
      farmer16,
      farmer17,
      farmer18,
      farmer19,
      loadingLeaderBoard: false,
    });
    
    this.loadFarmingLeaderboard();
  }

  async loadFarmingLeaderboard() {
    const { web3, numFarmers } = this.state;
    const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });
    
    let contractCallContext = [];
    for (let i = 0; i < numFarmers; i++) {
        contractCallContext.push({
            reference: `farmerData${i}`,
            contractAddress: MIRROR_ADDRESS,
            abi: MIRRORABI,
            calls: [
                { methodName: 'farmingLUT', methodParameters: [i] }
            ]
        });
    }

    const callResults = await multicall.call(contractCallContext);

    let farmingLeaderboardCalls = [];
    for (let i = 0; i < numFarmers; i++) {
        const farmerAddress = callResults.results[`farmerData${i}`].callsReturnContext[0].returnValues[0];
        farmingLeaderboardCalls.push({
            reference: `farmerAmount${i}`,
            contractAddress: MIRROR_ADDRESS,
            abi: MIRRORABI,
            calls: [
                { methodName: 'farmingLeaderboard', methodParameters: [farmerAddress] }
            ]
        });
    }

    const leaderboardResults = await multicall.call(farmingLeaderboardCalls);

    let farmingData = [];
    for (let i = 0; i < numFarmers; i++) {
        const amount = leaderboardResults.results[`farmerAmount${i}`].callsReturnContext[0].returnValues[0].hex*1/(10**18);
        const address = callResults.results[`farmerData${i}`].callsReturnContext[0].returnValues[0];

        farmingData.push({ address, amount: parseInt(amount) });
    }

    // Sort the array based on the farming amount
    farmingData.sort((a, b) => b.amount - a.amount);

    // Only take the top 10 farmers
    const topFarmers = farmingData

    // Display the top farmers
    this.setState({ topFarmers });

   
}


  sortFunction(a, b) {
    if (a[0] === b[0]) {
      return 0;
    } else {
      return a[0] < b[0] ? -1 : 1;
    }
  }

  

  async getMirrorPrice() {
    /*const priceObject = await CoinGeckoClient.simple.price({
      ids: 'mirror-farm',
      vs_currencies: 'usd',
    })
    const mirrorObject = priceObject.data['mirror-farm']
    this.setState({ mirrorPrice: mirrorObject.usd })
    */
  }
  //{color: this.state.showTab2 ? "#A933ff" : "#000", borderColor: "#fff", backgroundcolor: "#fff",  }
  closeWindow = (id) => {
    this.setState(prevState => ({
      showWindows: prevState.showWindows.map((show, index) => index === id ? false : show)
    }));
  };

  renderWindow(title, content, id) {
    if (!this.state.showWindows[id]) return null;

    const positionX = 30 + 350 * (id % 2); // Example positioning logic
    const positionY = 60 + 120 * Math.floor(id / 3); // Adjust these calculations as needed

    return (
      <Rnd
        key={id}
        default={{
          x: positionX,
          y: positionY,
          width: 320,
          height: 200,
        }}
        bounds="parent"
      >
        <div className="window">
          <div className="window-titlebar">
            {title}
            <button onClick={() => this.closeWindow(id)}>X</button>
          </div>
          <div className="window-content">{content}</div>
        </div>
      </Rnd>
    );
  }
  
  renderTerminal() {
    // Define the messages for the terminal
    const message1 = "Good afternoon Batman Bin Suparman, it looks like you are not the hero we need and deserve since you were in jail in 2013. But now that you have logged in, we need your help. Our Gathum city is in shambles. There is a token called BBS and it is making everyone who holds the token, auto stake and the last 5 buyers are getting extra earnings. This is a trade to earn situation. We need your help to put a stop to this. We know you can't since you are probably tempted to purchase BBS yourself, but do not. We do not condone this behavior. You can help us by spreading the news about this so others do not buy. Batman Bin Suparman, you are our last hope. Remember 'mums' is our code name for this mission - Chief Doodoo Inspector Jemes Gardon";
    const message2 = "CA: 0x922DE007904a612Cf427aF3dFFA38c72BBaDB66d, Dev Fee: 2%, Wanted: 3%, Network: FTM, Reward: BBS, Telegram: https://t.me/BBS_Portal/";
    const message2Part1 = "CA: ";
    const message2Part2 = ", Dev Fee: 2%, Wanted: 3%, Network: FTM, Reward: BBS, Telegram: https://t.me/BBS_Portal/";
  
    if (!this.messageInterval) {
      this.messageInterval = setInterval(() => {
        this.setState(prevState => ({ terminalMessage: !prevState.terminalMessage }));
      }, 30000);
    }
    return (
      <Rnd
        default={{
          x: 900, // Adjust the position as needed
          y: 300,
          width: 500, // Adjust the size as needed
          height: 300,
        }}
        minWidth={400} // Set minimum width
        minHeight={300} // Set minimum height
        bounds="parent"
      >
        <div className="terminal-window">
          <div className="window-titlebar">
            Batman Bin Suparman TX/RX
            <button onClick={() => this.closeWindow('terminal')}>X</button>
          </div>
          <div className="terminal-content">
            <div className="transmission-animation">Transmission Incoming...</div>
            <div style={{fontSize:"12px"}}>
              {this.state.terminalMessage ? message1 : (
                <>
                  {message2Part1}
                  <a href={`https://www.dextools.io/app/en/fantom/pair-explorer/${MIRROR_ADDRESS}`} target="_blank" rel="noopener noreferrer">
                    {MIRROR_ADDRESS}
                  </a>
                  {message2Part2}
                </>
              )}
            </div>
            <div className="transmission-animation">...New Transmission Incoming</div>
          </div>
        </div>
      </Rnd>
    );
  }
  
  shortenAddress(address) {
    if (!address) return '';
    const firstPart = address.slice(0, 6); // Takes the first 5 characters including '0x'
    const lastPart = address.slice(-4); // Takes the last 3 characters
    return `${firstPart}...${lastPart}`;
  }
  
  
  renderMostWantedWindow() {
    const farmers = [
      this.state.farmer0, this.state.farmer1, this.state.farmer2, this.state.farmer3, this.state.farmer4,
      this.state.farmer5, this.state.farmer6, this.state.farmer7, this.state.farmer8, this.state.farmer9,
      this.state.farmer10, this.state.farmer11, this.state.farmer12, this.state.farmer13, this.state.farmer14,
      this.state.farmer15, this.state.farmer16, this.state.farmer17, this.state.farmer18, this.state.farmer19
    ];
  
    return (
      <Rnd
        key="most-wanted"
        default={{
          x: 30,
          y: 400,
          width: 1000,
          height: 400,
        }}
        bounds="parent"
        minWidth={400}
        minHeight={400}
      >
        <div className="window">
          <div className="window-titlebar">
            Latest 20 Most Wanted List
            <button onClick={() => this.closeWindow('most-wanted')}>X</button>
          </div>
          <div className="window-content">
            <div className="most-wanted-window">
              {farmers.map((farmer, index) => (
                <div key={index} className="farmer-card">
                  <a href={`https://ftmscan.com/address/${farmer}`} target="_blank" rel="noopener noreferrer">
                    <img src={this.state.shuffledVillains[index % 10]} alt={`Villain ${index % 10}`} className="villain-image"/>
                  </a>
                  <div className="farmer-address">{this.shortenAddress(farmer)}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Rnd>
    );
  }
  

handleHomeworkClick = () => {
  const password = prompt("What's our secret code phrase:");
  if (password === "mums") {
    this.setState({showMobileHomework: true });
  } else {
    alert("Incorrect password");
  }
};

handleLeaderboardClick = () => {
  this.setState({ showMobileLeaderboard: true });
};

handleTerminalClick = () => {
  this.setState({ showMobileTerminal: true });
};
 
 // Add new methods for handling button clicks
 handleTelegramClick = () => {
  window.open("https://t.me/BBS_Portal", "_blank");
};

handleBuyBBSClick = () => {
  window.open("https://spooky.fi/#/swap?outputCurrency=0xbA306ab667637Ca8B3D36F1BB22d80627842e48Al", "_blank");
};

handleTwitterClick = () => {
  window.open("https://twitter.com/BBS_ERC20/", "_blank");
}

handleChartClick = () => {
  window.open("https://www.dextools.io/app/en/fantom/pair-explorer/0x6216c5cf1edfd7f0411a2f87b8d83991bbda58ae", "_blank");
}

handleEmailClick = () => {
  window.open("mailto:supramabinbats@proton.me", "_blank");
}

  
  // Method to handle secret folder click
handleSecretClick = () => {
  const password = prompt("What's our secret code phrase:");
  if (password === "mums") {
    this.setState({ isSecretUnlocked: true, showSecretContent: true });
  } else {
    alert("Incorrect password");
  }
};
  
  
renderBSCPrompt() {
  return (
      <>
          <div className="overlay" onClick={() => this.setState({ notBSC: false })}></div>
          <Rnd
              key="bsc-prompt"
              default={{
                  x: (window.innerWidth - 300) / 2,
                  y: (window.innerHeight - 150) / 2,
                  width: 300,
                  height: 150,
              }}
              bounds="parent"
              disableDragging
              enableResizing={false}
          >
              <div className="bsc-prompt" style={{ zIndex: 1000 }}>
                  <div className="window-titlebar">
                      Fantom Network Warning
                      <button onClick={() => this.setState({ notBSC: false })}>X</button>
                  </div>
                  <div className="window-content">
                      <p>You are not connected to the Fantom network. Please switch networks in your wallet. After you switch, please refresh your browser.</p>
                  </div>
              </div>
          </Rnd>
      </>
  );
}

renderLeaderboardWindow() {
  if (!this.state.showLeaderboard) return null;
  
  return (
    <Rnd
      key="leaderboard"
      default={{
        x: 300, y: 200, width: 700, height: 300,
      }}
      bounds="parent"
    >
      <div className="window">
        <div className="window-titlebar">
          Top 10 World wide Offenders Leaderboard
          <button onClick={() => this.setState({ showLeaderboard: false })}>X</button>
        </div>
        <div className="window-content">
          <ul>
            {this.state.topFarmers.map((farmer, index) => (
              <div key={index}>
                {index + 1}. <a href={`https://ftmscan.com/address/${farmer.address}`} target="_blank" rel="noopener noreferrer">
                  {farmer.address}
                </a> - {this.formatNumber(farmer.amount)}
              </div>
            ))}
          </ul>
        </div>
      </div>
    </Rnd>
  );
}




//MAIN RENDER//
  render() {
    const { 
      loading, account, mirrorBalance, morWholeBurn, totalFees, numFarmers, 
      farmer0, farmer1, farmer2, farmer3, farmer4,farmer5,
      farmer6,
      farmer7,
      farmer8,
      farmer9,
      farmer10,
      farmer11,
      farmer12,
      farmer13,
      farmer14,
      farmer15,
      farmer16,
      farmer17,
      farmer18,
      farmer19,
      // Other state variables...
    } = this.state;
    const blurClass = this.state.notBSC ? 'blur-background' : '';
    
      
        return(
            <> 
            { this.state.loading 
              ?<div className="loading-screen">
                  <div className="retro-title">
                    Welcome Batman Bin Suparman <br/>
                    Please Login To <br/>
                    Gathum City Police Department Portal
                    <span className="blinking-cursor">▮</span>
                  </div>
                  
                  {/* Button Container */}
                  <div className="button-container">
                    <button className="retro-button" onClick={() => this.connectToBSC()}>
                      Login
                    </button>
                    <button className="retro-button" onClick={this.handleTelegramClick}>
                      Telegram
                    </button>
                    <button className="retro-button" onClick={this.handleBuyBBSClick}>
                      Buy BBS
                    </button>
                    <button className="retro-button" onClick={this.handleTwitterClick}>
                      Twitter
                    </button>
                    <button className="retro-button" onClick={this.handleChartClick}>
                      DexTools Chart
                    </button>
                    <button className="retro-button" onClick={this.handleEmailClick}>
                      E-mail
                    </button>
                  </div>

                  <div className="retro-id-display">
                
                  </div>
                  <div className="retro-id-text">
                    ID: Batman Bin Suparman
                  </div>
              </div>
              : <>
                    <main className={`gotham-desktop ${blurClass}`}>
                      <div className="top-bar">
                        {<span>Welcome to Gathum City Police Department Dashboard Batman Bin Suparman,  Your ID: {account}</span>}
                      </div>
                      {this.isMobile() ?
                        <> 
                          <div>
                            
                            {this.renderMobileFolders()}
                            {this.renderMobileWindow()}
                            {this.renderMobileTerminal()}
                            {this.state.showMobileHomework && this.renderMobileSecretContent()}
                            {this.renderMobileLeaderboard()}
                            {this.state.showMobileTerminal && this.renderMobileMessage()}
                          </div>
                        </> 
                        :
                        <> 
                        {this.renderFolders()} {/* Render folder icons here */}
                          {this.renderWindow('Wallet Balance', <div>{mirrorBalance} BBS</div>, 1)}
                          {this.renderWindow('BBS_Burn', <div>{morWholeBurn}</div>, 2)}
                          {this.renderWindow('Total Fees', <div>{totalFees}</div>, 3)}
                          {this.renderWindow('Total_Offenders (Holders)', <div>{numFarmers}</div>, 4)}
          
                          {this.renderMostWantedWindow()}
                          {this.state.showSecretContent && this.renderSecretContent()}
                          {this.renderLeaderboardWindow()}
          
                          {/* This will render BSC prompt */}
                          {this.renderTerminal()}
                          
                        </>
                      }
                      
                      
      
                      <div className="status-bar">Status: Connected</div>
                    
                </main> 
                { this.state.notBSC ? <>{this.renderBSCPrompt()} </> : <></>}  
                </>
            }
            </>
          );
        
    }

    renderMobileSecretContent(){
      if (!this.state.showMobileHomework) return null;
      
      const {enlargeNotepad}=this.state;
      return (
        <Rnd
          default={{
            x: 0,
            y: 70,
            width:420,
            height:2900,
          }}
          >
          <div className="window">
            <div className="window-titlebar">
              Homework
              <button onClick={() => this.setState({showMobileHomework: false })}>X</button>
            </div>
            <div className="window-content">
              {/* Add the content for the secret window here */}
              <div className="window-content secret-window">
              <div className="secret-item"><img src={idCard} alt="ID Card" /></div>
              <div className={`secret-item ${enlargeNotepad ? 'enlarged' : ''}`}
                    onClick={this.toggleEnlargeNotepad}>
                  <img src={notepadText} alt="Notepad" />
                  </div>
              <div className="secret-item"><img src={meme1} alt="Meme 1" /></div>
              <div className="secret-item"><img src={meme2} alt="Meme 2" /></div>
              {/* Add more memes or content as needed */}
            </div>
              {/* Add more memes or content as needed */}
            </div>
          </div>
        </Rnd>
  );
    }

    renderMobileLeaderboard() {
      if (!this.state.showMobileLeaderboard) return null;
      return (
        <Rnd
          key="leaderboard"
          default={{
            x: 10, y: 70, width: 400, height: 600,
          }}
          
        >
          <div className="window">
            <div className="window-titlebar">
              Top 10 World wide Offenders Leaderboard
              <button onClick={() => this.setState({ showMobileLeaderboard: false })}>X</button>
            </div>
            <div className="window-content" style={{justifyContent:"left", padding:"0px", marginLeft:"0px", fontSize:"12px"}}>
              <ul>
                {this.state.topFarmers.map((farmer, index) => (
                  <div key={index}>
                    {index + 1}. {farmer.address} - {this.formatNumber(farmer.amount)}
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </Rnd>
      );
    }

    renderMobileMessage(){
          // Define the messages for the terminal
        const message1 = "Good afternoon Batman Bin Suparman, it looks like you are not the hero we need and deserve since you were in jail in 2013. But now that you have logged in, we need your help. Our Gathum city is in shambles. There is a token called BBS and it is making everyone who holds the token, auto stake and the last 5 buyers are getting extra earnings. This is a trade to earn situation. We need your help to put a stop to this. We know you can't since you are probably tempted to purchase BBS yourself, but do not. We do not condone this behavior. You can help us by spreading the news about this so others do not buy. Batman Bin Suparman, you are our last hope. Remember 'mums' is our code name for this mission - Chief Doodoo Inspector Jemes Gardon";
        const message2 = "CA: 0x922DE007904a612Cf427aF3dFFA38c72BBaDB66d, Dev Fee: 2%, Wanted: 3%, Network: FTM, Reward: BBS, Telegram: https://t.me/BBS_Portal/";
        const message2Part1 = "CA: ";
        const message2Part2 = ", Dev Fee: 2%, Wanted: 3%, Network: FTM, Reward: BBS, Telegram: https://t.me/BBS_Portal/";

        
        
        if (!this.messageInterval) {
          this.messageInterval = setInterval(() => {
            this.setState(prevState => ({ terminalMessage: !prevState.terminalMessage }));
          }, 30000);
        }
        return (
          <Rnd
            default={{
              x: 10, // Adjust the position as needed
              y: 70,
              width: 360, // Adjust the size as needed
              height: 700,
            }}
            
            
          >
            <div className="terminal-window">
              <div className="window-titlebar">
                Batman Bin Suparman TX/RX
                <button onClick={() => this.closeWindow('terminal')}>X</button>
              </div>
              <div className="terminal-content">
                <div className="transmission-animation">Transmission Incoming...</div>
                <div style={{fontSize:"12px"}}>
                  {this.state.terminalMessage ? message1 : (
                    <>
                      {message2Part1}
                      <a href={`https://www.dextools.io/app/en/fantom/pair-explorer/${MIRROR_ADDRESS}`} target="_blank" rel="noopener noreferrer">
                        {MIRROR_ADDRESS}
                      </a>
                      {message2Part2}
                    </>
                  )}
                </div>
                <div className="transmission-animation">...New Transmission Incoming</div>
              </div>
            </div>
          </Rnd>
        );
    }
    
    
    

    renderMobileTerminal() {
      const farmers = [
        this.state.farmer0, this.state.farmer1, this.state.farmer2, this.state.farmer3, this.state.farmer4,
        this.state.farmer5, this.state.farmer6, this.state.farmer7, this.state.farmer8, this.state.farmer9,
      ];

      
      return (
        <Rnd
          default={{
            x: 230,
            y: 580,
            width: '40%',
            height: 'auto',
          }}
          
        >
          <div className="terminal-window">
            <div className="window-titlebar">
              Last 20 Earners
              <button onClick={() => {/* Close window logic here */}}>X</button>
            </div>
            <div className="terminal-content">
                  {farmers.map((farmer, index) => (
                      <div className="farmer-address">{this.shortenAddress(farmer)},</div>
                  ))}
              </div>
          </div>
        </Rnd>
      );
    }
    


    renderMobileWindow = () => {
      return (
        <Rnd
          default={{
            x: 5,
            y: 350,
            width: '110%',
            height: 'auto',
          }}
          
        >
          <div className="window">
            <div className="window-titlebar">
              Dashboard
              <button onClick={() => {/* Close window logic here */}}>X</button>
            </div>
            <div className="window-content">
              {/* Add the contents of your windows here */}
                  <div>
                <strong>CA:</strong>
                <span style={{fontSize:"13px"}}>
                  <a href={`https://www.dextools.io/app/en/fantom/pair-explorer/${MIRROR_ADDRESS}`} target="_blank" rel="noopener noreferrer">
                    {MIRROR_ADDRESS}
                  </a>
                </span>
              </div>
              <div><strong>Wallet Balance:</strong> {this.state.mirrorBalance} BBS</div>
              <div><strong>BBS Burn:</strong> {this.state.morWholeBurn}</div>
              <div><strong>Total Fees:</strong> {this.state.totalFees}</div>
              <div><strong>Total Offenders:</strong> {this.state.numFarmers}</div>
              {/* Include other data as necessary */}
            </div>
          </div>
        </Rnd>
      );
    }
    


renderFolders() {
  // Folder names and corresponding window indexes
  const folders = [
    { name: 'Balance', index: 1 },
    { name: 'BBS_Burn', index: 2 },
    { name: 'Total_Fees', index: 3 },
    { name: 'Total_Jailed', index: 4 },
    { name: 'Most_Wanted', index: 5 },
    { name: 'Leaderboard', index: 6 }, // Add this line
  ];

  return (
    <div className="folder-container">
      {folders.map(folder => (
        <div 
          key={folder.index} 
          className="folder-icon" 
          onClick={() => this.openWindow(folder.index)}
        >
          <img src={folderIcon} alt={folder.name} />
          <div>{folder.name}</div>
        </div>
      ))}
      <div className="folder-icon" onClick={this.handleSecretClick}>
        <img src={folderIcon} alt="Secret" />
        <div>Homework</div>
      </div>
      
    </div>
  );
}

renderMobileFolders() {
  return (
    <div className="folder-container">
      <div className="folder-icon" onClick={this.handleHomeworkClick}>
        <img src={folderIcon} alt="Secret" />
        <div>Homework</div>
      </div>
      <div className="folder-icon" onClick={this.handleLeaderboardClick}>
        <img src={folderIcon} alt="Leaderboard" />
        <div>Leaderboard</div>
      </div>
      <div className="folder-icon" onClick={this.handleTerminalClick}>
        <img src={folderIcon} alt="Terminal" />
        <div>Terminal</div>
      </div>
    </div>
  );
}

 // Toggle function for enlarging the notepad
 toggleEnlargeNotepad = () => {
  this.setState(prevState => ({ enlargeNotepad: !prevState.enlargeNotepad }));
};

renderSecretContent() {
  if (!this.state.isSecretUnlocked) return null;
  const {enlargeNotepad}=this.state;
  return (
    <Rnd
      key="secret-content"
      default={{
        x: 200,
        y: 200,
        width: 900,
        height: 400,
      }}
      bounds="parent"
    >
      <div className="window">
        <div className="window-titlebar">
          Homework
          <button onClick={() => this.setState({ showSecretContent: false })}>X</button>
        </div>
        <div className="window-content">
          {/* Add the content for the secret window here */}
          <div className="window-content secret-window">
          <div className="secret-item"><img src={idCard} alt="ID Card" /></div>
          <div className={`secret-item ${enlargeNotepad ? 'enlarged' : ''}`}
                 onClick={this.toggleEnlargeNotepad}>
              <img src={notepadText} alt="Notepad" />
              </div>
          <div className="secret-item"><img src={meme1} alt="Meme 1" /></div>
          <div className="secret-item"><img src={meme2} alt="Meme 2" /></div>
          {/* Add more memes or content as needed */}
        </div>
          {/* Add more memes or content as needed */}
        </div>
      </div>
    </Rnd>
  );
}

openWindow(index) {
  // Set the corresponding window to visible
  this.setState(prevState => ({
    showWindows: prevState.showWindows.map((show, idx) => idx === index ? true : show)
  }));
  if (index === 6) { // If leaderboard folder is clicked
    this.setState({ showLeaderboard: true });
  } else {
    // ... existing logic for other windows
  }
}

formatNumber = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(2).replace(/\.0$/, '') + 'G';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(2).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(2).replace(/\.0$/, '') + 'K';
  }
  return num;
};

}

